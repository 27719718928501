import { LogoutButton } from 'auth'
import { ReactComponent as DashboardIcon } from 'img/dashboard.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './Header.module.scss'
import { useMsal } from '@azure/msal-react'
import ToggleSwitch from 'common/components/ToggleSwitch/ToggleSwitch'
import React, { useContext } from 'react'
import { AutoRefreshContext } from 'layout/MainLayout'

interface HeaderProps {
    shouldDisableAutoRefresh?: boolean
    setAutoRefresh: (value: boolean) => void
}

export default function Header({
    setAutoRefresh,
    shouldDisableAutoRefresh,
}: HeaderProps): JSX.Element {
    const { t } = useTranslation()
    const { accounts } = useMsal()
    const autoRefresh = useContext(AutoRefreshContext)

    const onToggleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAutoRefresh(e.target.checked)
    }
    return (
        <div className={style.header}>
            <Link className={style.iconLink} to={'/'}>
                <DashboardIcon className={style.dashboardIcon} />
            </Link>
            <h1>
                <Link to={'/'}>{t('headerTitle')}</Link>
            </h1>
            <ToggleSwitch
                label="Auto Refresh"
                onChange={onToggleCheck}
                checked={shouldDisableAutoRefresh ? false : autoRefresh}
                disabled={shouldDisableAutoRefresh}
            />
            {accounts.length > 0 && <LogoutButton />}
        </div>
    )
}
