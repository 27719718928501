import { Card, Loadable, Spinner, SpinnerWithHeight } from 'common'
import useCurrentClusterLoad, { ClusterLoadDatum } from 'controller/hooks/useCurrentClusterLoad'
import { Cluster } from 'controller/types/cluster'
import { ReactComponent as SubdirectoryIcon } from 'img/subdirectory.svg'
import { useTranslation } from 'react-i18next'

type ClusterBranch = {
    id: string | null
    children: ClusterBranch[]
}

function getPhaseLoad(cluster: Cluster, loadData: Loadable<ClusterLoadDatum[]>) {
    if (loadData.status === 'error') {
        return <span title={'Failed to load phase load ' + loadData.error}>...</span>
    }
    if (loadData.status === 'loading') {
        return <Spinner size={30} />
    }

    const clusterLoadData = loadData.data.find((x) => x.clusterId === cluster.id)

    if (!clusterLoadData) return '0'

    const phases = clusterLoadData.phases.map((phase) => phase.toFixed(1)) || ['-', '-', '-']

    return phases.join(', ')
}

function getClusterTree(clusters: Cluster[], parentId: string | null): ClusterBranch {
    return {
        id: parentId,
        children: clusters
            .filter((c) => c.parentId === parentId)
            .sort((a, b) => a.id.localeCompare(b.id))
            .map((c) => getClusterTree(clusters, c.id)),
    }
}

function renderClusterRow(
    cluster: Cluster,
    depth: number,
    loadPhase: string | JSX.Element
): JSX.Element {
    const depthClass = ['ml-0', 'ml-4', 'ml-8', 'ml-12', 'ml-16', 'ml-20', 'ml-24'][
        Math.min(depth - 1, 6)
    ]
    return (
        <tr key={cluster.id}>
            <td className="whitespace-nowrap flex flex-row align-middle">
                {depth > 0 && <SubdirectoryIcon className={`flex w-4 h-7 mr-1 ${depthClass}`} />}
                <div className="flex">{cluster.id}</div>
            </td>
            <td>{cluster.fuseLimit}</td>
            <td>{cluster.headroom}</td>
            <td>{cluster.netType}</td>
            <td>{loadPhase}</td>
        </tr>
    )
}

function renderClusters(
    clusterTree: ClusterBranch,
    clusters: Cluster[],
    depth: number,
    loadData: Loadable<ClusterLoadDatum[]>
): JSX.Element[] {
    let content: JSX.Element[] = []

    if (clusterTree.id) {
        const cluster = clusters.find((c) => c.id === clusterTree.id)!
        content.push(renderClusterRow(cluster, depth, getPhaseLoad(cluster, loadData)))
    }

    for (const branch of clusterTree.children) {
        if (branch.children.length > 0) {
            content.push(...renderClusters(branch, clusters, depth + 1, loadData))
        } else {
            const cluster = clusters.find((c) => c.id === branch.id)!
            content.push(renderClusterRow(cluster, depth + 1, getPhaseLoad(cluster, loadData)))
        }
    }

    return content
}

interface ClusterListProps {
    clusters: Loadable<Cluster[]>
    className?: string
    controllerId: string
}

export default function ClusterList({
    clusters,
    className,
    controllerId,
}: ClusterListProps): JSX.Element {
    const { t } = useTranslation()
    const loadData = useCurrentClusterLoad(controllerId)

    const getContent = () => {
        switch (clusters.status) {
            case 'error':
                return 'Failed to load clusters'
            case 'loading':
                return <SpinnerWithHeight height={282} />
            case 'success':
                const tree = getClusterTree(clusters.data, null)
                let rows = renderClusters(tree, clusters.data, -1, loadData)

                if (clusters.data.length > rows.length) {
                    rows.push(
                        ...clusters.data
                            .filter(
                                (c1) =>
                                    c1.parentId &&
                                    clusters.data.every((c2) => c1.parentId !== c2.id)
                            )
                            .map((c) => renderClusterRow(c, 0, getPhaseLoad(c, loadData)))
                    )
                }

                return (
                    <table>
                        <thead>
                            <tr>
                                <th>{t('clusterListTableColumnId')}</th>
                                <th>{t('clusterListTableColumnFuseLimit')}</th>
                                <th>{t('clusterListTableColumnHeadroom')}</th>
                                <th>{t('clusterListTableColumnNetType')}</th>
                                <th>{t('clusterListTableColumnLoad')}</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                )
        }
    }

    return (
        <Card title={t('clusterListTitle')} className={className}>
            {getContent()}
        </Card>
    )
}
