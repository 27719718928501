import { AutoRefreshContext, DisabledAutoRefreshContext } from 'layout/MainLayout'
import { useContext, useEffect, useState } from 'react'

// Refresh period is 3 minutes
const refreshPeriod = 180000

export default function useAutoRefresh() {
    const autoRefresh = useContext(AutoRefreshContext)
    const disabledAutoRefresh = useContext(DisabledAutoRefreshContext)
    const [refreshCounter, setRefreshCounter] = useState(0)

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null
        if (autoRefresh && !disabledAutoRefresh) {
            intervalId = setInterval(() => setRefreshCounter((prev) => prev + 1), refreshPeriod)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
    }, [autoRefresh, disabledAutoRefresh])

    return refreshCounter
}
