import { PopupModalContext, PopupModalMessage } from 'common/hooks/usePopupModal'
import React, { useState } from 'react'
import PopupModal from './components/PopupModal/PopupModal'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import style from './MainLayout.module.scss'
import { useLocalStorage } from 'common/hooks/useLocalStorage'

interface MainLayoutProps {
    children?: React.ReactNode
    shouldDisableAutoRefresh: boolean
}

export const AutoRefreshContext = React.createContext<boolean>(false)
export const DisabledAutoRefreshContext = React.createContext<boolean>(false)

export default function MainLayout({
    children,
    shouldDisableAutoRefresh,
}: MainLayoutProps): JSX.Element {
    const [popupMessages, setPopupMessages] = useState<PopupModalMessage[]>([])
    const [autoRefresh, setAutoRefresh] = useLocalStorage<boolean>(`autoRefresh`, false)

    return (
        <PopupModalContext.Provider
            value={(newMessage) => setPopupMessages((messages) => [...messages, newMessage])}
        >
            <AutoRefreshContext.Provider value={autoRefresh}>
                <DisabledAutoRefreshContext.Provider value={shouldDisableAutoRefresh}>
                    <div className={style.pageWrapper}>
                        <Header
                            setAutoRefresh={setAutoRefresh}
                            shouldDisableAutoRefresh={shouldDisableAutoRefresh}
                        />
                        <div className={style.content}>{children}</div>
                        <Footer />
                        {popupMessages.length > 0 && (
                            <PopupModal
                                show={popupMessages.length > 0}
                                close={() => setPopupMessages((messages) => messages.slice(0, -1))}
                                message={popupMessages.at(-1)!}
                            />
                        )}
                    </div>
                </DisabledAutoRefreshContext.Provider>
            </AutoRefreshContext.Provider>
        </PopupModalContext.Provider>
    )
}
