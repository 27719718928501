import classNames from 'classnames'
import style from './ToggleSwitch.module.scss'

interface ToggleSwitchProps {
    label?: string
    checked?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
}

export default function ToggleSwitch({ label, checked, onChange, disabled }: ToggleSwitchProps) {
    return (
        <div className="flex items-center gap-1">
            <label className={classNames(style.switch, disabled && style.disabled)}>
                <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
                <span className={style.slider}></span>
            </label>
            {label && <label className="text-white text-lg">{label}</label>}
        </div>
    )
}
