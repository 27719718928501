import { DelayedLoadable, Loadable } from 'common'
import { GraphQLError } from 'graphql'
import { useEffect, useMemo } from 'react'
import { CombinedError, useQuery } from 'urql'
import useAutoRefresh from './useAutoRefresh'

export interface GraphQLErrors {
    errors: GraphQLError[]
}

export default function useGraphQL<TData>(
    query: string,
    variables?: Record<string, string | number | null>
): [Loadable<TData, CombinedError>, () => void] {
    const [result, reexecuteQuery] = useQuery<TData>({ query, variables })
    const refreshCounter = useAutoRefresh()

    useEffect(() => {
        reexecuteQuery()
    }, [refreshCounter, reexecuteQuery])

    return useMemo(() => {
        if (result.fetching) {
            return [{ status: 'loading' }, reexecuteQuery]
        }

        if (result.error) {
            return [{ status: 'error', error: result.error }, reexecuteQuery]
        }

        return [{ status: 'success', data: result.data! }, reexecuteQuery]
    }, [result, reexecuteQuery])
}

export function useGraphQlDelayed<TData>(
    query: string,
    variables?: Record<string, string | number | null>,
    pause: boolean = true
): [DelayedLoadable<TData, GraphQLErrors>, () => void] {
    const [result, reexecuteQuery] = useQuery<TData>({
        query,
        variables,
        pause: true,
    })
    const refreshCounter = useAutoRefresh()

    const requestState = useMemo<DelayedLoadable<TData, GraphQLErrors>>(() => {
        const { data, error, fetching } = result

        if (error) {
            return { status: 'error', error: { errors: error.graphQLErrors } }
        } else if (data) {
            return { status: 'success', data }
        } else if (fetching || !pause) {
            return { status: 'loading' }
        } else {
            return { status: 'idle' }
        }
    }, [result, pause])

    useEffect(() => {
        !pause && reexecuteQuery()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCounter])

    return [requestState, reexecuteQuery]
}
