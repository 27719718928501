import classNames from 'classnames'
import { MouseEventHandler } from 'react'
import style from './Button.module.scss'
import Spinner from '../Spinner/Spinner'

interface ButtonProps {
    click: MouseEventHandler<HTMLButtonElement>
    type: 'primary' | 'secondary'
    children?: React.ReactNode
    className?: string
    dense?: boolean
    disabled?: boolean
    title?: string
    loading?: boolean
}

export function Button({
    click,
    type,
    children,
    className,
    dense,
    disabled,
    title,
    loading = false,
}: ButtonProps): JSX.Element {
    const classes = classNames(style.button, {
        [style.primary]: type === 'primary',
        [style.secondary]: type === 'secondary',
        [style.dense]: dense,
        [style.disabled]: disabled,
        [`${className}`]: className,
    })

    return (
        <button disabled={disabled} className={classes} onClick={click} title={title}>
            {loading ? <Spinner className={style.spinner} size={30} /> : children}
        </button>
    )
}
