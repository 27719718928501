import { InteractionRequiredAuthError } from '@azure/msal-common'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import { Loadable } from 'common'
import useAutoRefresh from 'common/hooks/useAutoRefresh'
import { useCallback, useEffect, useState } from 'react'

export interface Icontroller {
    id: string
    name: string
    isCommissioned: boolean
    businessUnit: string
    migrationStatus: string
}

type ControllerList = Array<Icontroller>

export function useControllerList(): [
    Loadable<ControllerList>,
    (bustCache: boolean, includeUnprovisioned: boolean) => void
] {
    const { instance: msalInstance, accounts: msalAccounts } = useMsal()
    const [requestState, setRequestState] = useState<Loadable<ControllerList>>(() => ({
        status: 'loading',
    }))
    const refreshCounter = useAutoRefresh()

    const fetch = useCallback(
        (bustCache: boolean, includeUnprovisioned: boolean) => {
            setRequestState({ status: 'loading' })
            const msalArgs = {
                scopes: [process.env.REACT_APP_BACKEND_API_SCOPE as string],
                account: msalAccounts[0],
            }
            msalInstance
                .acquireTokenSilent(msalArgs)
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        msalInstance.acquireTokenRedirect(msalArgs)
                    }
                    throw error
                })
                .then((res) =>
                    axios.get(
                        `${
                            process.env.REACT_APP_BACKEND_API_URL
                        }/api/controllers?bustCache=${String(
                            bustCache
                        )}&includeUnprovisioned=${String(includeUnprovisioned)}`,
                        {
                            headers: {
                                Authorization: `Bearer ${res.accessToken}`,
                            },
                        }
                    )
                )
                .then((res) => setRequestState({ status: 'success', data: res.data }))
                .catch((error) => setRequestState({ status: 'error', error: error }))
        },
        [msalAccounts, msalInstance]
    )

    useEffect(() => fetch(false, false), [fetch, refreshCounter])

    return [requestState, fetch]
}
