import { InteractionType } from '@azure/msal-browser'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from '@azure/msal-react'
import { ChargePointPage } from 'chargePoint'
import { config } from 'config'
import { ControllerLogsDirectPage, ControllerLogsPage, ControllerPage } from 'controller'
import ControllerConfigPage from 'config/pages/ControllerConfigPage'
import DeviceDetailsPage from 'controller/pages/DeviceDetailsPage'
import { OverviewPage } from 'overview'
import { matchPath, Route, Routes, useLocation } from 'react-router-dom'
import MainLayout from './layout/MainLayout'
import LocationPage from 'location/pages/LocationPage'
import LocationListPage from 'location/pages/LocationListPage'
import ControllerFirmwarePage from 'controller/pages/ControllerFirmwarePage'
import ChargePointDiagnosticsPage from 'chargePoint/pages/ChargePointPage/ChargePointDiagnosticsPage'
import ControllerScanPage from 'controller/pages/ControllerScanPage'
import ElectricianViewPage from 'electricianView/pages/ElectricianViewPage'
import ChargePointTransactionssPage from 'chargePoint/pages/ChargePointPage/ChargePointTransactionsPage'
import IssuesPage from 'controller/pages/IssuesPage'
import ProvisioningPage from 'provisioning/pages/ProvisioningPage'
import { DevicesBatchPage } from 'devicesBatch'
import ControllerOcppServerPage from 'controller/pages/ControllerOcppServerPage'

function shouldRedirect(path: string): boolean {
    // Regex match on location/:locationId/controller/:controllerId/electrician/:accessToken
    const regex = /location\/[^/]+\/controller\/[^/]+\/electrician\/[^/]+/
    return !regex.test(path)
}

export default function Router(): JSX.Element {
    const location = useLocation()
    const disabledAutoRefreshPaths = [
        '/location/:locationId/controller/:id/config',
        '/location/:locationId/controller/:controllerId/electrician',
        '/location/:locationId/controller/:controllerId/electrician/:accessToken',
        '/',
        '/controller/:controllerId/ocpp',
        '/controller/:controllerId/scan',
        'controller/:controllerId/firmware',
    ]

    // Check if any path matches
    const isAutoRefreshDisabled = disabledAutoRefreshPaths.some((path) =>
        matchPath(path, location.pathname)
    )

    // We want to redirect to the login page if the user is not authenticated, but
    // not if the user is on the electrician view page.

    // Get the current path
    const path = window.location.pathname

    // Set the interaction type to redirect if the user is not on the electrician view page
    const interactionType = shouldRedirect(path) ? InteractionType.Redirect : InteractionType.Silent

    useMsalAuthentication(interactionType, {
        scopes: config.auth.scopes,
        extraScopesToConsent: config.auth.extraScopesToConsent,
        redirectUri: config.auth.redirectUri,
    })

    return (
        <>
            <AuthenticatedTemplate>
                <MainLayout shouldDisableAutoRefresh={isAutoRefreshDisabled}>
                    <Routes>
                        <Route path="controller/:controllerId" element={<ControllerPage />} />
                        <Route
                            path="controller/:controllerId/details"
                            element={<DeviceDetailsPage />}
                        />
                        <Route
                            path="controller/:controllerId/logs"
                            element={<ControllerLogsPage />}
                        />
                        <Route
                            path="controller/:controllerId/logs/direct"
                            element={<ControllerLogsDirectPage />}
                        />
                        <Route
                            path="controller/:controllerId/chargepoint/:chargePointId"
                            element={<ChargePointPage />}
                        />
                        <Route
                            path="controller/:controllerId/chargepoint/:chargePointId/diagnostics"
                            element={<ChargePointDiagnosticsPage />}
                        />
                        <Route
                            path="controller/:controllerId/chargepoint/:chargePointId/transactions"
                            element={<ChargePointTransactionssPage />}
                        />
                        <Route
                            path="controller/:controllerId/firmware"
                            element={<ControllerFirmwarePage />}
                        />
                        <Route
                            path="controller/:controllerId/scan"
                            element={<ControllerScanPage />}
                        />
                        <Route
                            path="controller/:controllerId/ocpp"
                            element={<ControllerOcppServerPage />}
                        />
                        <Route
                            path="location/:locationId/controller/:controllerId/electrician/:ignored"
                            element={<ElectricianViewPage />}
                        />
                        <Route
                            path="location/:locationId/controller/:controllerId/electrician"
                            element={<ElectricianViewPage />}
                        />
                        <Route path="location/:locationId" element={<LocationPage />} />
                        <Route path="issues/" element={<IssuesPage />} />
                        <Route path="locations/" element={<LocationListPage />} />
                        <Route path="provisioning/" element={<ProvisioningPage />} />
                        <Route path="devicesBatch/" element={<DevicesBatchPage />} />
                        <Route
                            path="location/:locationId/controller/:id/config"
                            element={<ControllerConfigPage />}
                        />
                        <Route path="*" element={<OverviewPage />} />
                    </Routes>
                </MainLayout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <MainLayout shouldDisableAutoRefresh={isAutoRefreshDisabled}>
                    <Routes>
                        <Route
                            path="location/:locationId/controller/:controllerId/electrician/:accessToken"
                            element={<ElectricianViewPage />}
                        />
                        {/* <Route path="*" element={<LoginPage />} /> */}
                    </Routes>
                </MainLayout>
            </UnauthenticatedTemplate>
        </>
    )
}
