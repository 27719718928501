import { ChargePoint } from 'chargePoint'
import { Card, ConfirmModalButtonRow, IconButton, Loadable, Modal, SpinnerWithHeight } from 'common'
import {
    EditingMode,
    SortingMode,
    Table,
    IKaTableProps,
    ITableProps,
    kaReducer,
    SortDirection,
    DataType,
    ActionType,
} from 'ka-table'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ReactComponent as LinkIcon } from 'img/link.svg'
import { ReactComponent as LinkOffIcon } from 'img/link-off.svg'
import { useEffect, useState } from 'react'
import { DispatchFunc } from 'ka-table/types'
import { ReactComponent as RebootIcon } from 'img/restart.svg'
import useRebootChargePoint from 'chargePoint/hooks/useRebootChargePoint'
import style from './ChargePointList.module.scss'
import { defaultKaTableSort } from 'common/utils/kaTableSort'

interface ChargePointListProps {
    chargePoints: Loadable<ChargePoint[]>
    className?: string
    controllerId: string
}

export default function ChargePointList({
    chargePoints,
    className,
    controllerId,
}: ChargePointListProps): JSX.Element {
    const { t } = useTranslation()
    const [showRebootModal, setShowRebootModal] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [rebootChargePointState, executeRebootChargePoint, resetRebootChargePointState] =
        useRebootChargePoint()

    const getIsConnectedContent = (isConnected: boolean | undefined) => {
        if (isConnected === true) {
            return <LinkIcon className="fill-green-600 w-4" />
        } else if (isConnected === false) {
            return <LinkOffIcon className="fill-yellow-600 w-4" />
        }
    }

    const closeRebootModal = () => {
        if (rebootChargePointState.status !== 'loading') {
            resetRebootChargePointState()
        }
        setShowRebootModal(false)
    }

    const getRebootModalContent = () => {
        if (chargePoints.status !== 'success') return null
        switch (rebootChargePointState.status) {
            case 'idle':
                return (
                    <>
                        <h2>{t('multipleChargePointsRebootTitle')}</h2>
                        <p>{t('multipleChargePointDetailsRebootModalContent')}</p>
                        <ConfirmModalButtonRow
                            onConfirm={() => {
                                const chargePointsToReboot = tableProps.selectedRows || []
                                chargePointsToReboot.forEach((chargePointId) => {
                                    executeRebootChargePoint({
                                        chargePointId,
                                        deviceId: controllerId,
                                    })
                                })
                                dispatch({
                                    type: ActionType.DeselectAllFilteredRows,
                                    value: {
                                        selectedRows: [],
                                    },
                                })
                            }}
                            onCancel={closeRebootModal}
                        />
                    </>
                )
            case 'loading':
                return (
                    <>
                        <SpinnerWithHeight height={120} />
                    </>
                )
            case 'success':
                return (
                    <>
                        <p>{t('multipleChargePointDetailsRebootSuccess')}</p>
                    </>
                )
            case 'error':
                return (
                    <>
                        <h2>{`Something went wrong when rebooting charge point`}</h2>
                        <p>{rebootChargePointState.error.toString()}</p>
                    </>
                )
        }
    }

    const tablePropsInit: IKaTableProps = {
        columns: [
            {
                key: 'selection-cell',
                width: 64,
            },
            { key: 'isConnected', title: t('chargePointListTableColumnStatus'), width: 64 },
            {
                key: 'id',
                title: t('chargePointListTableColumnId'),
                width: 128,
                sortDirection: SortDirection.Ascend,
                dataType: DataType.String,
                filterRowValue: '',
            },
            { key: 'accessControl', title: t('chargePointListTableColumnAccessControl') },
            { key: 'clusterId', title: t('chargePointListTableColumnCluster') },
            { key: 'firmwareVersion', title: t('chargePointListTableColumnFirmwareVersion') },
            { key: 'connectors', title: t('chargePointListTableColumnConnectors') },
        ],
        data: [],
        rowKeyField: 'id',
        sortingMode: SortingMode.Single,
        selectedRows: [],
        sort: defaultKaTableSort,
        editingMode: EditingMode.None,
        searchText: searchText,
        search: ({ searchText, rowData, column }) => {
            if (column.key === 'id') {
                return rowData.id.toLowerCase().includes(searchText.toLowerCase())
            }
            return false
        },
        // filteringMode: FilteringMode.FilterRow,
    }

    const [tableProps, changeTableProps] = useState(tablePropsInit)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    useEffect(() => {
        if (chargePoints.status !== 'success') return
        changeTableProps((prevState) => ({
            ...prevState,
            data: chargePoints.data.map((chargePoint, index) => ({
                ...chargePoint,
                index,
                connectors: (
                    <div className="flex flex-col">
                        {chargePoint.connectors
                            .filter((x) => x.state)
                            .map((connector, index) => (
                                <div
                                    key={index}
                                    className="text-xs font-mono text-right w-36"
                                >{`#${connector.id} - ${connector.state}`}</div>
                            ))}
                    </div>
                ),
            })),
        }))
    }, [changeTableProps, chargePoints])

    let content: JSX.Element | string = <SpinnerWithHeight height={144} />
    switch (chargePoints.status) {
        case 'success':
            content = (
                <>
                    <div className={style.actionRow}>
                        {(tableProps.selectedRows?.length ?? 0) > 0 && (
                            <IconButton
                                click={() => setShowRebootModal(true)}
                                icon={RebootIcon}
                                text={t('chargePointDetailsReboot')}
                                title={t('multipleChargePointsRebootTitle')}
                                type="primary"
                            />
                        )}
                        <input
                            className="ml-auto max-w-sm my-2"
                            placeholder={t('search')}
                            type="search"
                            value={searchText}
                            onChange={(event) => {
                                setSearchText(event.currentTarget.value)
                                dispatch({ type: 'Search', searchText: event.currentTarget.value })
                            }}
                        />
                    </div>
                    <Table
                        {...tableProps}
                        dispatch={dispatch}
                        childComponents={{
                            cellText: {
                                content: (props) => {
                                    const chargePoint = props.rowData as ChargePoint
                                    switch (props.column.key) {
                                        case 'id':
                                            const chargePointLink = `/controller/${chargePoint.controllerId}/chargepoint/${chargePoint.id}`
                                            return (
                                                <Link to={chargePointLink}>{chargePoint.id}</Link>
                                            )
                                        case 'isConnected':
                                            return getIsConnectedContent(props.value)
                                        case 'connectors':
                                            return props.value
                                        // .filter((connector: Connector) => connector.state)
                                        // .map((connector: Connector) => `${connector.id}-${connector.state}`)
                                        case 'selection-cell':
                                            const { rowKeyValue } = props
                                            return (
                                                <input
                                                    type="checkbox"
                                                    checked={tableProps.selectedRows?.includes(
                                                        rowKeyValue
                                                    )}
                                                    onChange={() => {
                                                        const isRowSelected =
                                                            tableProps.selectedRows?.includes(
                                                                rowKeyValue
                                                            )

                                                        if (isRowSelected) {
                                                            dispatch({
                                                                type: ActionType.DeselectRow,
                                                                rowKeyValue,
                                                            })
                                                            return
                                                        }

                                                        dispatch({
                                                            type: ActionType.SelectRow,
                                                            rowKeyValue,
                                                        })
                                                    }}
                                                />
                                            )
                                    }
                                },
                            },
                            headCell: {
                                content: ({ column }) => {
                                    if (column.key === 'selection-cell') {
                                        return (
                                            <input
                                                type="checkbox"
                                                checked={
                                                    (tableProps.data?.length ?? 0) > 0 &&
                                                    tableProps.selectedRows?.length ===
                                                        tableProps.data?.length
                                                }
                                                onChange={() => {
                                                    const isAllRowsSelected =
                                                        tableProps.selectedRows?.length ===
                                                        tableProps.data?.length

                                                    if (isAllRowsSelected) {
                                                        dispatch({
                                                            type: ActionType.DeselectAllFilteredRows,
                                                            value: {
                                                                selectedRows: [],
                                                            },
                                                        })
                                                        return
                                                    }

                                                    dispatch({
                                                        type: ActionType.SelectAllFilteredRows,
                                                        value: {
                                                            selectedRows: tableProps.data?.map(
                                                                (row) => row.id
                                                            ),
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                },
                            },
                        }}
                    />
                </>
            )
            break
        case 'error':
            content = chargePoints.error.toString()
            break
    }

    return (
        <Card title={t('chargePointListTitle')} className={className}>
            {content}
            <Modal show={showRebootModal} onClickOutside={closeRebootModal} className={style.modal}>
                {getRebootModalContent()}
            </Modal>
        </Card>
    )
}
