import { Card, IconButton, SpinnerWithHeight } from 'common'
import { Icontroller, useControllerList } from 'controller/hooks/useControllerList'
import { ReactComponent as OfflineIcon } from 'img/offline.svg'
import { ReactComponent as RefreshIcon } from 'img/refresh.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './ControllerList.module.scss'

export default function ControllerList() {
    const [filter, setFilter] = useState<string>('')
    const [includeUnprovisioned, setIncludeUnprovisioned] = useState<boolean>(false)
    const { t } = useTranslation()
    const [req, refetch] = useControllerList()

    const controllerFilter = (controller: Icontroller) => {
        return (
            controller.id.toLowerCase().includes(filter) ||
            controller.name.toLowerCase().includes(filter)
        )
    }

    const getContent = () => {
        switch (req.status) {
            case 'error':
                return `Failed to load controllers: ${req.error}`
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'success':
                return (
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('controllerListDescriptionId')}</th>
                                    <th>{t('controllerListDescriptionName')}</th>
                                    <th>{t('controllerBusinessUnit')}</th>
                                    <th>{t('controllerMigrationStatus')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {req.data
                                    .sort((a, b) => a.id.localeCompare(b.id))
                                    .filter((c) => c.isCommissioned)
                                    .concat(req.data.filter((c) => !c.isCommissioned))
                                    .filter(controllerFilter)
                                    .map((controller) => (
                                        <tr
                                            key={controller.id}
                                            className={
                                                !controller.isCommissioned
                                                    ? style.unCommissioned
                                                    : ''
                                            }
                                        >
                                            <td>
                                                <Link
                                                    to={`/controller/${controller.id}`}
                                                    state={{ isRedirectedFromControllerList: true }}
                                                    className={style.idWrapper}
                                                >
                                                    {!controller.isCommissioned && (
                                                        <OfflineIcon
                                                            className={style.offlineIcon}
                                                        />
                                                    )}
                                                    <span>{controller.id}</span>
                                                </Link>
                                            </td>
                                            <td>{controller.name}</td>
                                            <td>{controller.businessUnit}</td>
                                            <td>{controller.migrationStatus}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </>
                )
        }
    }

    return (
        <Card>
            <div className={style.headerWrapper}>
                <h2>{t('controllerListTitle')}</h2>
                <div className={style.checkboxContainer}>
                    <input
                        id="includeUnprovisioned"
                        type="checkbox"
                        className={style.checkbox}
                        checked={includeUnprovisioned}
                        onChange={() => {
                            refetch(false, !includeUnprovisioned)
                            setIncludeUnprovisioned(!includeUnprovisioned)
                        }}
                    />
                    <label htmlFor="includeUnprovisioned">
                        {t('controllerListIncludeUnprovisioned')}
                    </label>
                </div>
                <IconButton
                    text={t('controllerListRefresh')}
                    click={() => refetch(true, includeUnprovisioned)}
                    icon={RefreshIcon}
                    type="secondary"
                />
            </div>
            <input
                placeholder={t('searchInputPlaceholder')}
                className={style.searchInput}
                onChange={(e) => setFilter(e.target.value.toLowerCase())}
            />
            {getContent()}
        </Card>
    )
}
