import {
    Breadcrumb,
    Button,
    Card,
    IconLink,
    NavigationBar,
    OverviewPageLink,
    ValidatedSelectField,
    ValidatedTextField,
} from 'common'
import { DevicesBatchPageLink } from 'common/components/Breadcrumb/Breadcrumb'
import { ReactComponent as WrenchIcon } from 'img/wrench-cog.svg'
import { ReactComponent as LocationIcon } from 'img/location.svg'
import { ReactComponent as SatelliteIcon } from 'img/satelliteUplink.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './DevicesBatchPage.module.scss'
import { useControllerIssues } from 'controller/hooks/useControllerIssues'
import { useNavigate } from 'react-router-dom'
import { PreviewTable, useInsertDevicesBatch, useGetHardwareVersions } from '../index'
import useValidatedField from 'common/hooks/useValidatedField'
import { stringNonEmptyValidator } from 'config/utils/validators'

export type possibleDelimeters = ',' | ';'

const devicesArrayToPayload = (devicesArray: string[], delimeter: possibleDelimeters) => {
    const devicesPayload = devicesArray.map((device) => {
        const deviceData = device.split(delimeter)
        return {
            serialNumber: deviceData[0],
            primaryMac: deviceData[1],
            secondaryMac: deviceData[2],
        }
    })
    return devicesPayload
}

function dataToDevicesArray(data: string) {
    const devices = data.split('\n').map((deviceData) => deviceData.trim())
    return devices
}

function dataPreviewAndPayload(data: string, delimeter: possibleDelimeters) {
    return {
        payload: devicesArrayToPayload(dataToDevicesArray(data), delimeter),
    }
}

export default function DevicesBatchPage() {
    const { t } = useTranslation()
    const req = useControllerIssues()
    const navigate = useNavigate()
    const [allHardwareVersions] = useGetHardwareVersions()
    const batchName = useValidatedField<string>('', stringNonEmptyValidator)
    const deviceListData = useValidatedField<string>('', (data) => {
        return dataToDevicesArray(data).every((device) => device.length > 25)
    })
    const hardwareVersion = useValidatedField<string>('', stringNonEmptyValidator)
    const delimeter = useValidatedField<possibleDelimeters>(',', stringNonEmptyValidator)
    const formFileds = [batchName, deviceListData, hardwareVersion, delimeter]
    const allValid = formFileds.every((x) => x.isValid)

    const [insertDeviceBatchState, insertDeviceBatch] = useInsertDevicesBatch()
    const [showPreview, setShowPreview] = useState<boolean>(false)

    const isHardwareVersionsLoaded = allHardwareVersions.status === 'success'
    const isRequestSuccess = req.status === 'success'
    const issuesLinkText =
        isRequestSuccess && req.data.length > 0
            ? `${t('goToIssuesPageButtonTitle')}${
                  isRequestSuccess ? ` (${req.data.length})` : null
              }`
            : t('goToIssuesPageButtonTitle')

    function onInsertDeviceBatch(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault()
        const payload = {
            input: {
                batchName: batchName.value,
                hardwareVersionId: hardwareVersion.value,
                devices: dataPreviewAndPayload(deviceListData.value, delimeter.value).payload,
            },
        }
        insertDeviceBatch(payload)
        navigate('/')
        return
    }

    return (
        <>
            <NavigationBar>
                <Breadcrumb className={style.breadcrumb}>
                    <OverviewPageLink />
                    <DevicesBatchPageLink />
                </Breadcrumb>
                <IconLink
                    icon={LocationIcon}
                    to="/locations"
                    text={t('overviewPageLocationsLink')}
                />
                <IconLink icon={WrenchIcon} to="/issues" text={issuesLinkText} />
                <IconLink
                    icon={SatelliteIcon}
                    to="/provisioning"
                    text={t('provisioningPageLink')}
                />
            </NavigationBar>
            <Card title={t('importBatchPageTitle')}>
                <form className={style.assignToLocationForm}>
                    <div className={`${style.informationSection} mt-2`}>
                        <span className={style.label}>
                            <strong>{t('batchNameLabel')}:</strong>
                        </span>
                        <ValidatedTextField
                            field={batchName}
                            title={''}
                            placeholder={t('batchNamePlaceholder')}
                            className={style.batchName}
                        />
                    </div>
                    <div className={style.informationSection}>
                        <label htmlFor="hardwareVersionDropDown" className="">
                            <strong>{t('controllerDetailsDescriptionHardwareVersion')}:</strong>
                        </label>
                        <ValidatedSelectField
                            field={hardwareVersion}
                            options={
                                isHardwareVersionsLoaded
                                    ? allHardwareVersions.data.map((hwVersion) => ({
                                          value: hwVersion.id,
                                          text: hwVersion.hwVersion,
                                      }))
                                    : []
                            }
                            title={''}
                            disabled={!isHardwareVersionsLoaded}
                            className={style.hardwareVersion}
                            placeholder={t('controllerDetailsDescriptionHardwareVersion')}
                        />
                    </div>
                    <h3>{t('importDeviceList')}</h3>
                    <div className={style.informationSection}>
                        <span className={style.label}>
                            <strong>{t('devicesListData')}:</strong>
                            <h6>{t(`devicesListDataMinCharacters`)}</h6>
                        </span>
                        <ValidatedTextField
                            field={deviceListData}
                            title={''}
                            placeholder={t('importCsvDataPlaceholder')}
                            classNameInner={style.devicesListData}
                            className={style.devicesListData}
                            multiline
                        />
                        <div className="flex">
                            <label htmlFor="csvSeparatorDropdown" className="w-max">
                                <strong className="ml-2">{t('delimeter')}:</strong>
                            </label>
                            <ValidatedSelectField
                                field={delimeter}
                                classNameInner={style.delimeter}
                                className={style.delimeter}
                                forceModified={true}
                                options={[
                                    { value: ',', text: 'Comma (,)' },
                                    { value: ';', text: 'Semicolon (;)' },
                                ]}
                                title={''}
                            />
                        </div>
                    </div>
                    <div className={`mt-4 ${style.informationSection}`}>
                        <span className={style.label}></span>
                        <Button
                            type="secondary"
                            disabled={
                                deviceListData.value.length <= 25 ||
                                allHardwareVersions.status === 'loading'
                            }
                            click={(e) => {
                                e.preventDefault()
                                setShowPreview((prevState) => !prevState)
                                dataPreviewAndPayload(deviceListData.value, delimeter.value)
                            }}
                        >
                            {t('previewDataButton')}
                        </Button>
                    </div>

                    {deviceListData.value.length > 25 && showPreview ? (
                        <PreviewTable
                            data={
                                dataPreviewAndPayload(deviceListData.value, delimeter.value).payload
                            }
                        />
                    ) : null}
                    <div className={`mt-10 ${style.informationSection}`}>
                        <Button
                            loading={insertDeviceBatchState.status === 'loading'}
                            type="secondary"
                            disabled={!allValid || insertDeviceBatchState.status === 'loading'}
                            click={onInsertDeviceBatch}
                        >
                            {t('importSerialNumbers')}
                        </Button>
                    </div>
                </form>
            </Card>
        </>
    )
}
