import { Loadable } from 'common'
import sortInPlaceByKey from 'common/utils/sortInPlaceByKey'
import moment from 'moment'
import { useEffect, useState } from 'react'
import useClusterLoadFetchFunction from './useClusterLoadFetchFunction'

export interface ClusterLoadDatum {
    clusterId: string
    phases: number[]
    date: string
}

export default function useCurrentClusterLoad(controllerId: string) {
    const fetchFn = useClusterLoadFetchFunction(controllerId)

    const [req, setReq] = useState<Loadable<ClusterLoadDatum[]>>({ status: 'loading' })

    useEffect(() => {
        fetchFn(moment().subtract(1, 'hour').toISOString(), moment().add(1, 'day').toISOString(), 1)
            .then((res) =>
                setReq({
                    status: 'success',
                    data: res.clusters.flatMap((cluster) => {
                        const latestRow = sortInPlaceByKey(cluster.data, (x) => x[0]).at(-1)
                        if (!latestRow) {
                            return []
                        }
                        return [
                            {
                                clusterId: cluster.clusterId,
                                phases: latestRow.slice(2, 5) as number[],
                                date: latestRow[0],
                            },
                        ]
                    }),
                })
            )
            .catch((err) => setReq({ status: 'error', error: err }))
    }, [fetchFn])

    return req
}
